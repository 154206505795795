// extracted by mini-css-extract-plugin
export var ArtistDescription = "GérardZlotykamien-module--ArtistDescription--GsV8b";
export var ArtistInfos = "GérardZlotykamien-module--ArtistInfos--ekqZy";
export var ButtonWrapper = "GérardZlotykamien-module--ButtonWrapper --IR+y6";
export var CardWrapper = "GérardZlotykamien-module--CardWrapper--05jpI";
export var CarrouselWrapper2 = "GérardZlotykamien-module--CarrouselWrapper2--AQPlH";
export var Citations = "GérardZlotykamien-module--Citations--uarH4";
export var DescriptionWrapper = "GérardZlotykamien-module--DescriptionWrapper--C96Zf";
export var ImageWrapper = "GérardZlotykamien-module--ImageWrapper--FOyrF";
export var LinkWrapper = "GérardZlotykamien-module--LinkWrapper--Sbh4h";
export var MobileProtrait = "GérardZlotykamien-module--MobileProtrait--WKiGa";
export var More = "GérardZlotykamien-module--More--qT2yb";
export var MoreButton = "GérardZlotykamien-module--MoreButton--EgzhH";
export var NameWrapper = "GérardZlotykamien-module--NameWrapper--AkX53";
export var PdpWrapper = "GérardZlotykamien-module--PdpWrapper--0P-UA";
export var PhotosWrapper = "GérardZlotykamien-module--PhotosWrapper--R8Ueh";
export var ProfilWrapper = "GérardZlotykamien-module--ProfilWrapper--b0czE";
export var TitleWrapper = "GérardZlotykamien-module--TitleWrapper--FQxkq";
export var Wrapper = "GérardZlotykamien-module--Wrapper--jOKIv";