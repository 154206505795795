
import * as React from "react";
import {
  Wrapper,
  PdpWrapper,
  DescriptionWrapper,
ArtistDescription,
  Citations,
  PhotosWrapper,
  CardWrapper,
  ProfilWrapper,
  NameWrapper,
  CarrouselWrapper2,
  LinkWrapper,
  ButtonWrapper,
  TitleWrapper,
  ArtistInfos,
  ImageWrapper,
  MobileProtrait,
  MoreButton,
  More,
} from "./GérardZlotykamien.module.css";
import Title from "./../../../../components/Title";
import Carousel from 'react-multi-carousel';
import GérardZlotykamienPhoto1 from "../../../../res/Photos site/Gerard Zlotykamien/ZLOTYKAMIEN.jpeg"
import GérardZlotykamienPhoto2 from "../../../../res/Photos site/Gerard Zlotykamien/ZLOTYKAMIEN2.jpeg"
import GérardZlotykamienPhoto3 from "../../../../res/Photos site/Gerard Zlotykamien/Zlotykamien3.webp"
import GérardZlotykamienPhoto4 from "../../../../res/Photos site/Gerard Zlotykamien/5.jpg"
import GérardZlotykamienPhoto5 from "../../../../res/Photos site/Gerard Zlotykamien/6.jpg"
import GérardZlotykamienPhoto6 from "../../../../res/Photos site/Gerard Zlotykamien/7.jpg"
import GérardZlotykamienPhoto7 from "../../../../res/Photos site/Photos Oeuvres Disponibles/Gerard Zlotykamien - Mobilisation - 1965.png"
import CarrouselCartel from "../../../../components/CarrouselCartel";
import Button from "./../../../../components/Button";
import Pdp from '../../../../res/zlotykamien/portrait.jpg'
const isBrowser = typeof window !== "undefined";
const infos ={
  path: "GérardZlotykamien",
  name: "GérardZlotykamien",
  description:
  "Gérard Zlotykamien began painting in 1955. His meeting with Yves Klein, who gave him the rigor of the creative act, was decisive in his career. His first street interventions date from 1963, when he painted ghostly silhouettes with an enema bulb and then with an aerosol can in the immense construction site known as the \"trou des Halles\" in Paris. His drawings, which he calls \"ephemeral\" evoke the human shadows that were printed on the walls after the Hiroshima explosion. An executive at Galeries Lafayette by day, a painter by night, very much on the fringe of the art world, Zlotykamien painted his ephemera in many cities around the world: Leipzig (Germany), Cape Town (South Africa). In 1980, he was prosecuted by the city of ULM in Germany for having painted more than 70 \"ephemera\" on the walls of the city. He was released. However, in 1984, he was fined six hundred francs, suspended, for having painted on the walls of the National Foundation of Graphic and Plastic Arts. In 2009, Gérard Zlotykamien participates in the retrospective Né dans la Rue at the Fondation Cartier, and in 2019 in the exhibition Conquête urbaine at the Musée des Beaux-Arts in Calais. He was made a Knight of the Order of Arts and Letters in 2015.",
  pdp: Pdp,
  alt_pdp: "Photo de profil de GérardZlotykamien.",
  photos: [
    { src: GérardZlotykamienPhoto3, name: "GérardZlotykamien" },
    { src: GérardZlotykamienPhoto4, name: "GérardZlotykamien" },
    { src: GérardZlotykamienPhoto6, name: "GérardZlotykamien" },
  ],
  citations: []
};

const infos2 = {
  photos: [
    { src: GérardZlotykamienPhoto7, name: "Gérard Zlotykamien", artist:"Gérard Zlotykamien\n(né en 1940)", title:"Mobilisation, 1965", desc1: "Techniques mixtes sur toile", desc2 :"Mixed media on canvas", desc3: "120 x 90 cm", desc4:" 47,2 x 35,4 in.", desc5:"Signée et titrée au dos : \" MOBILISATION, Gérard Zlotykamien \"", desc6:"Signed and dated at the back : \" MOBILISATION, Gérard Zlotykamien \"" },
  ],
};


const GérardZlotykamien = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };
  return (
    <div className={Wrapper}>
      <div className={CardWrapper}>
        <div className={ProfilWrapper} id="f">
          <img className={PdpWrapper} src={infos.pdp} alt={infos.alt_pdp} />
        </div>
        <div className={ArtistDescription}>
          <h1 className={TitleWrapper}>GERARD ZLOTYKAMIEN <h2 style={{paddingLeft: "16px"}}>(French)</h2></h1>
          <h3 className={NameWrapper} >Born in 1940</h3>
          <div className={DescriptionWrapper} dangerouslySetInnerHTML={{__html:infos.description}}></div>
          <div  className={Citations}>
            {infos.citations.map((citations) => (
              <div>
                <p style={{fontStyle: "italic", textAlign: "center" }}>{citations}</p>
                <br />
              </div>
            ))}
          </div>
          <br />
        </div>
      </div>
      

      <div className={PhotosWrapper}>
       <Title title={"Past or Present"} />
        <div className={CarrouselWrapper2}>
          {/* <Carrousel images={infos.photos} />
           */}
           <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {infos.photos.map((image) => (
                <img
                  className={ImageWrapper}
                  key={image.name}
                  alt={image.name}
                  src={image.src}
                />
            ))}
          </Carousel>
        </div>
      </div>
      <div className={ButtonWrapper}>
            <Button
              alt=""
              onClick={() => {
                if (typeof window !== "undefined") {
                  window.location.href = "/en/contactez-nous";
                }
              }
            }
            >
              Contact-us
            </Button>
        </div>
    </div>
  );
};

export default GérardZlotykamien;